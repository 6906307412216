@mixin key($direction, $_size:$key_size, $_border_size:3px, $_offset:5px) {
  font: normal normal normal ($_size * 0.5)/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  position: absolute;
  text-align: center;
  display: block;
  transform-origin: 50% 50%;
  line-height: $_size - 2 * $_border_size;
  width: $_size - 2 * $_border_size;
  height: $_size - 2 * $_border_size;
  border-radius: 5px;
  border-style: solid;
  border-width: ($_border_size - 1px) $_border_size ($_border_size + 1px) $_border_size;
  background-size: cover;

  @if ($direction == "left") {
    background-image: url(../../data/yungbeef.png);
    border-color: $left_color;
    color: $left_color;
    content: "\f060";
    padding-right: $_offset;
    width: $_size - 2 * $_border_size - $_offset;
  } @else if ($direction == "up") {
    background-image: url(../../data/ctangana.png);
    border-color: $up_color;
    color: $up_color;
    content: "\f062";
    // opacity: 0.5;
    padding-bottom: $_offset;
    line-height: $_size - 2 * $_border_size - $_offset;
    height: $_size - 2 * $_border_size - $_offset;
  }  @else if ($direction == "right") {
    background-image: url(../../data/dgomez.png);
    border-color: $right_color;
    color: $right_color;
    content: "\f061";
    padding-left: $_offset;
    width: $_size - 2 * $_border_size - $_offset;
  }  @else if ($direction == "down") {
    background-image: url(../../data/kidkeo.png);
    border-color: $down_color;
    color: $down_color;
    content: "\f063";
    padding-top: $_offset;
    line-height: $_size - 2 * $_border_size - $_offset;
    height: $_size - 2 * $_border_size - $_offset;
  } @else {
    border-color: transparent;
  }
}

@mixin key_colorblind($direction, $_size:$key_size, $_border_size:4px, $_offset:5px) {
  @include key($direction, $_size, $_border_size, $_offset);

  color: black;
  @if ($direction == "left") {
    border-color: #3a3a3a;
    border-bottom-color: #e7e7e7;
    border-top-color: #e7e7e7;
    border-style: dashed;
    background-color: lighten($left_color, 30%);
  } @else if ($direction == "up") {
    border-color: black;
    background-color: lighten($up_color, 30%);
  }  @else if ($direction == "right") {
    border-color: #3a3a3a;
    border-left-color: #e7e7e7;
    border-right-color: #e7e7e7;
    border-width: $_border_size;
    background-color: lighten($right_color, 40%);
  }  @else if ($direction == "down") {
    border-color: #e7e7e7;
    border-width: $_border_size;
    background-color: lighten($down_color, 30%);
  }
}