@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    transform: scale(0.5);
    opacity: 0;
  }
}

@keyframes fadein {
  from {
    transform: scale(1.5);
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes reversedfadein {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

@keyframes lowfadeinwithdelay {
  0% {
    opacity: 0;
  }
  40% {
    transform: scale(0.7);
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes orbit {
  0% {
    transform: translateY($orbit_radius) rotate(0deg) translateY(-$orbit_radius) rotate(0deg) scale(0.1);
  }
  20% {
    transform: translateY($orbit_radius) rotate(-72deg) translateY(-$orbit_radius) rotate(72deg) scale(1);
  }
  96% {
    transform: translateY($orbit_radius) rotate(-359deg) translateY(-$orbit_radius) rotate(359deg) scale(1);
  }
  100% {
    transform: translateY($orbit_radius) rotate(-359deg) translateY(-$orbit_radius) rotate(359deg) scale(0.3);
  }
}

@keyframes mobile-orbit {
  0% {
    transform: translateY($orbit_radius * 0.6) rotate(0deg) translateY(-$orbit_radius * 0.6) rotate(0deg) scale(0.1);
  }
  20% {
    transform: translateY($orbit_radius * 0.6) rotate(-72deg) translateY(-$orbit_radius * 0.6) rotate(72deg) scale(1);
  }
  96% {
    transform: translateY($orbit_radius * 0.6) rotate(-359deg) translateY(-$orbit_radius * 0.6) rotate(359deg) scale(1);
  }
  100% {
    transform: translateY($orbit_radius * 0.6) rotate(-359deg) translateY(-$orbit_radius * 0.6) rotate(359deg) scale(0.3);
  }
}

@keyframes lowbump {
  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}


@keyframes bump {
  50% {
    transform: scale(2);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes selector-bump {
  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes selector-bad {
  0% {
    background-color: rgba(0, 255, 0, 0);
  }

  50% {
    background-color: rgba(255, 0, 0, 0.2);
    transform: scale(0.95) rotate(1deg);
  }

  100% {
    background-color: rgba(0, 255, 0, 0);
    transform: scale(1);
  }
}

@keyframes mobile-selector-bad {
  0% {
    background-color: rgb(255, 255, 255);
  }

  50% {
    background-color: rgb(255, 182, 195);
  }

  100% {
    background-color: rgb(255, 255, 255);
  }
}

@keyframes ding {
  0% {
    opacity: 1;
  }
  30% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(-30px);
  }
}

@keyframes color-arrow {
  0%, 10% {
    color: $up_color;
    transform: rotate(0deg) translateY(-5px);
  }
  15%, 35% {
    color: $right_color_alt;
    transform: rotate(90deg) translateY(-5px);
  }
  40%, 60% {
    color: $down_color;
    transform: rotate(180deg) translateY(-5px);
  }
  65%, 85% {
    color: $left_color;
    transform: rotate(270deg) translateY(-5px);
  }
  90%, 100% {
    color: $up_color;
    transform: rotate(359deg) translateY(-5px);
  }
}