body {
  // font-family: 'Inconsolata', monospace;
  // text-align: center;
  // background-color: $background_color;
  // padding-top: 20px;
  // color: #303030;
  // border-top: 1px solid $right_color;
  // margin: 0;

  h1,h2,h3 {
    font-weight: bold;
  }

  h1 {
    font-size: 16pt;
    margin-top: 23px;
    margin-bottom: 15px;
  }
  h2 {
    font-size: 14pt;
    margin-top: 23px;
    margin-bottom: 13px;
  }
  h3 {
    font-size: 12pt;
    margin-top: 6px;
    margin-bottom: 11px;
  }
  em {
    font-style: italic;
  }
  a {
    text-decoration: none;
    font-weight: bold;
  }
  strong {
    font-weight: bold;
  }
  i {
    padding-left: 1px;
  }
  hr {
    border: none;
    border-top: 1px solid #8b8b8b;
    margin-top: 40px;
  }
}

.mobile {
  max-width: $size - 20px;
  margin: 0 auto 20px auto;
  text-align: left;
  border-radius: 5px;
  background-color: rgba(black, 0.5);
  padding: 15px 10px;
  color: white;

  .centered {
    text-align: center;
  }

  a {
    color: white;
  }

  p {
    padding-bottom: 10px;
  }

  ul {
    text-align: center;
    li {
      padding: 5px 20px;
      margin: 5px 0px;
      display: inline-block;
      background-color: rgba(white, 0.1);
    }
  }
}

.container {
  max-width: $size;
  background-color: $board_color;
  border-radius: 5px;
  height: $size;
  position: relative;
  margin: 0 auto 10px auto;
  padding: 0;
  user-select: text;

  .key-selector-container {
    padding: $padding;

    &.hide {
      animation: fadeout 1s 1;
      opacity: 0;
    }

    &.show {
      animation: fadein 1s 1;
      opacity: 1;
    }

    .key-selector {
      position: absolute;
      margin: 0 auto;
      left: 0;
      right: 0;
      width: $selector_size;
      border-radius: 5px;
      height: $selector_size;
      opacity: 0;

      border-width: $selector_border_size;
      border-style: solid;
      border-left-color: $left_color;
      border-top-color: $up_color;
      border-right-color: $right_color;
      border-bottom-color: $down_color;

      &.show {
        opacity: 0.8;
      }

      &.fade {
        animation: fadein 1s;
        transform-origin: 50% 0;
      }

      &.bump {
        animation: selector-bump 0.25s 1 ease-in-out;
      }

      &.bad {
        animation: selector-bad 0.2s 1 linear;
      }

      &::after {
        margin: 5px;
        background-color: $board_color;
        opacity: 0.8;
      }

      &.s-key-left::after {
        @include key("left");
        border: none;
      }
      &.s-key-up::after {
        @include key("up");
        border: none;
      }
      &.s-key-right::after {
        @include key("right");
        border: none;
      }
      &.s-key-down::after {
        @include key("down");
        border: none;
      }

      .ding {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        font-family: 'Inconsolata', monospace;
        font-size: 15pt;
        animation: ding 0.5s 1 ease-out;
        opacity: 0;
        text-shadow: 3px 0 0 white, 0 -3px 0 white, 0 3px 0 white, -3px 0 0 white;
      }
    }
  }

  .logo {
    position: relative;
    width: 128px - 2 * $logo_border_size;
    height: 128px - 2 * $logo_border_size;
    margin: 0 auto 20px auto;
    background-color: rgba(white, 0.9);

    border-radius: 10px;
    border-width: $logo_border_size;
    border-style: solid;
    border-left-color: $left_color;
    border-top-color: $up_color;
    border-right-color: $right_color_alt;
    border-bottom-color: $down_color;
    opacity: 0.6;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      font: normal normal normal 64px/1 FontAwesome;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      transform: translate(0, 0);
      color: $up_color;
      content: "\f062";
      line-height: 128px - 2 * $logo_border_size;
      animation: color-arrow 6s infinite ease-out;
    }
  }

  .key {
    position: absolute;
    margin: (($selector_size - $key_size) / 2) auto;
    left: 0;
    right: 0;
    width: $key_size;
    height: $key_size;
    animation: orbit 2s 1 linear;
    top: $padding + $selector_border_size;

    &.paused, &.remove::after {
      animation-play-state: paused;
    }

    &.idle {
      animation: none;
      display: none;
    }

    &.hide::after {
      animation: fadeout 1s 1;
      opacity: 0;
    }

    &.key-left::after {
      @include key("left");
    }

    &.key-up::after {
      @include key("up");
    }

    &.key-right::after {
      @include key("right");
    }

    &.key-down::after {
      @include key("down");
    }

    &::after {
      background-color: $key_color;
    }
  }

  .level-message {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    line-height: $size;
    width: 100%;
    height: 100%;
    font-size: 17px;

    &.show {
      display: block;
      animation: reversedfadein 1s;
    }
  }

  .helper-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;

    &.hide {
      animation: fadeout 1s;
      opacity: 0;
    }

    .top {
      text-align: left;

      .colorblind-btn {
        padding: 12px;

        .icon {
          &::before {
            content: "\f06e";
          }
        }
        .help {
          display: none;

          &::after {
            content: " off";
          }
        }
        &:hover {
          cursor: pointer;
          background-color: rgba(black, 0.1);

          .icon {
            color: #4e4e4e;
          }
          .help {
            display: inline-block;
          }
        }
      }
    }

    .bottom {
      position: absolute;
      bottom: (2 * $key_size + $helper_spacing*5);
      left: 0;
      right: 0;
      max-width: $size;
      margin: 0 auto;

      .text {
        position: absolute;
        left: 0;
        right: 0;
        top: $key_size * 2 + $helper_spacing * 2;
        // max-width: $size*0.75;
        margin: 0 auto;
        font-size: 14pt;
        color: rgba(black, 0.7);
      }

      .key {
        top: 0;
        opacity: 1;
        animation: none;

        &::after {
          animation: lowbump 0.8s infinite ease-out;
        }

        &.key-left::after {
          top: $key_size + $helper_spacing;
          right: $key_size + $helper_spacing;
        }
        &.key-up::after {
          top: 0;
        }
        &.key-down::after {
          top: $key_size + $helper_spacing;
        }
        &.key-right::after {
          top: $key_size + $helper_spacing;
          right: 0;
          left: $key_size + $helper_spacing;
        }
      }
    }
  }

  .results {
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.05);
    border-radius: 5px;
    opacity: 0;

    &.show {
      display: table;
      animation: lowfadeinwithdelay 1.8s ease-in-out;
      opacity: 1;
    }

    &.hide {
      display: table;
      animation: fadeout 0.8s ease-in-out;
      opacity: 0;
    }

    .results-container {
      display: table-cell;
      vertical-align: middle;

      .results-content {
        margin-left: auto;
        margin-right: auto;

        p {
          animation: lowbump 0.8s infinite ease-out;
        }
      }
    }
  }

  .pause {
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #cacaca;
    border-radius: 5px;

    &.show {
      display: table;
    }

    .pause-container {
      display: table-cell;
      vertical-align: middle;

      .pause-content {
        margin-left: auto;
        margin-right: auto;

        p {
          animation: lowbump 0.8s infinite ease-out;
        }
      }
    }
  }

  .total {
    position: absolute;
    max-width: $size;
    border-radius: 0 0 5px 5px;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: 0;
    .percent {
      height: 6px;
      margin: 0 auto;
      transition: width 0.2s ease-in-out, background-color 0.2s linear;
      background-color: rgb(0, 219, 0);
      border-radius: 5px;

      &.medium {
        background-color: rgb(255, 208, 0);
      }

      &.low {
        background-color: rgb(255, 57, 45);
      }
    }
  }
}

.points-container {
  max-width: $size + 2*$padding;
  text-align: center;
  font-family: 'Inconsolata', monospace;
  margin: 0 auto 20px auto;
  opacity: 0;

  .bump {
    animation: bump 0.3s 1 ease-in-out;
  }

  &.hide {
    animation: fadeout 0.5s ease-out;
    opacity: 0;
  }

  &.show {
    animation: fadein 0.5s ease-out;
    opacity: 1;
  }
}

.best {
  max-width: $size;
  margin: 0 auto;
  text-align: right;
  padding-right: 5px;
  font-size: 16px;

  .best-points {
    font-weight: bold;
  }
}

.about {
  max-width: $size - 20px;
  margin: 0 auto;
  text-align: justify;
  font-size: 16px;
  padding-top: 20px;

  p {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .prim {
    color: #3F3E40;
    font-weight: bold;
  }

  .sec {
    color: #3F3E40;
    font-weight: bold;
  }

  .third {
    color: $up_color;
    font-weight: bold;
  }

  .key-up {
    display: inline-block;

    &::after {
      @include key("up", 21px, 2px, 0);
      position: relative;
    }
  }

  .tiny {
    font-size: 12px;
  }

  .share {
    padding-bottom: 20px;
    padding-top: 10px;
    text-align: center;
    min-height: 30px;

    &.big {
      min-height: 100px;
    }

    .reddit-share-button {
      top: -3px;
      vertical-align: middle;
      padding-bottom: 6px;
    }

    .fb-share-button {
      top: -3px;
    }

    .reddit-share-button {
      vertical-align: top;
    }
  }

  .footer {
    text-align: right;
  }
}

.mobile-controls {
  position: relative;
  display: none;
  max-width: $size;
  margin: 0 auto;
  user-select: none;

  .top {
    text-align: center;
    padding-bottom: 10px;

    .key-up {
      display: inline-block;

      &::after {
        @include key("up", $key_size+15px);
        position: relative;
      }
    }
  }

  .bottom {
    text-align: center;
    padding-bottom: 10px;

    .key-left {
      display: inline-block;

      &::after {
        @include key("left", $key_size+15px);
        position: relative;
      }
    }
    .key-down {
      display: inline-block;

      &::after {
        @include key("down", $key_size+15px);
        position: relative;
      }
    }
    .key-right {
      display: inline-block;

      &::after {
        @include key("right", $key_size+15px);
        position: relative;
      }
    }
  }

  .pause-btn {
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(black, 0.1);
    padding: 13px 17px;
    margin-right: 7px;
    border-radius: 5px;
  }
}

.colorblind {
  .helper-container {
    .top {
      .colorblind-btn {
        .icon {
          &::before {
            content: "\f070";
          }
        }
        .help {
          display: inline-block;

          &::after {
            content: " on";
          }
        }
        &:hover {
          cursor: pointer;

          .icon {
            color: #4e4e4e;
          }
        }
      }
    }
  }

  .key {
    &.key-left::after {
      @include key_colorblind("left");
    }

    &.key-up::after {
      @include key_colorblind("up");
    }

    &.key-right::after {
      @include key_colorblind("right");
    }

    &.key-down::after {
      @include key_colorblind("down");
    }
  }

  .key-selector-container {
    .key-selector {
      border-left-style: dashed;
    }
  }
}